.index-time {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    background: #FFFFFF;
    border-radius: 4px;
    text-align: center;
    padding: 4px 8px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.index-time-active {
    background: #00BF71;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    display: flex;
    color: #FFFFFF;
    cursor: pointer;
}

.value-chart-label {
    margin-top: 13px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #00BF71;
}

.home-page .recharts-default-legend {
    display: none;
}

.home-page-content {
    max-width: 1350px;
}

.option {
    color: #BFC3C8;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.option-active {
    color: #00BF71;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border-bottom: 2px solid #00BF71;
    cursor: pointer;
}

.market-moment .title {
    color: #344054;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.market-moment .label {
    color: #667085;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.market-moment .items {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.market-moment .change {
    font-size: 13px;
    line-height: 15px;
    color: #00BF71;
}

.line-items-chart {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 8px 12px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    background: #c9fbc9;
    font-size: 14px;
}

.change-table-value {
    font-size: 16px;
    line-height: 19px;
    color: #00BF71;
}

.charts-box {
    background: #FFFFFF;
    padding: 10px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 16px;
}

.charts-box .title {
    top: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    padding: 10px;
}

.active-item {
    color: #2BCA89;
    border-bottom: #2BCA89 solid 2px;
}

.line-hidden .recharts-xAxis, 
.line-hidden .recharts-cartesian-axis-line, 
.line-hidden .recharts-cartesian-axis-tick-line,
.line-hidden .recharts-line-dot {
  display: none;
}