.box-info-right {
    background: #E1F9EF;
    border: 1px solid #7CD2AF;
    box-shadow: 0px 8px 16px rgba(124, 210, 175, 0.35);
    border-radius: 8px;
}

.box-info-right .title {
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: #1D2939;
}

.text-blue {
    color: #2B7FFF;
}

.text-green {
    color: #1AAF76;
}

.bg-green {
    background-color: #1AAF76;
}

.title-chart {
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    color: #1D2939;
}

.box-time {
    border-radius: 4px;
    width: 33px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-g {
    background: linear-gradient(90deg, #00BF71 -1.42%, #FFCD6B 51%, #FF1826 98.29%);
    border-radius: 4px;
}

.box-content {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    min-height: 250px;
}

.box-content-2 {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.detail {
    color: #2B7FFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    border-color: #2B7FFF;
    padding-top: 20px;
}

.box-footer {
    background: #E1F9EF;
    border: 1px solid #7CD2AF;
    border-radius: 4px;
}

.number {
    font-weight: 800;
    font-size: 32px;
    line-height: 150%;
}

.text-ssm {
    font-size: 14px;
}

.table-stats {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.content-scroll {
    max-height: 180px;
    overflow-y: auto;
}

.filter-active {
    color: white;
    font-weight: 700;
    background-color: #1AAF76;
    border: none;
}

.css-187mznn-MuiSlider-root {
    height: 8px !important;
    color: #00BF71 !important;
}
.css-eg0mwd-MuiSlider-thumb {
    width: 12px !important;
    height: 12px !important;
}

.css-14pt78w-MuiSlider-rail {
    opacity: 0.1 !important;
}

.info-box {
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #00BF71;
}

::-webkit-scrollbar {
    width: 10px;
}

.pie-chart .apexcharts-graphical {
    transform: none;
}

@media (max-width: 640px) {
    .pie-chart .apexcharts-graphical {
        transform: translateX(12%);
    }
}

@media (max-width: 600px) {
    .title-chart {
        font-size: 24px;
    }
    .text-ssm {
        font-size: 12px;
    }
}
