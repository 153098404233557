.bg-main-green {
    background-color: #00BF71;
}

.bg-main-red {
    background-color: #F04438;
}

.value-chart .recharts-cartesian-grid-vertical, .vol-chart .recharts-cartesian-grid-vertical{
    display: none;
}