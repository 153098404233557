.homePage{
  @media (min-width: 1200px){
    .home-page-layout{
      padding: 0 113px 0 113px;
      max-width: 1750px;
      margin: auto;
    }
  }
  
  @media (max-width: 800px){
    .home-page-layout{
      @apply px-4;
    }
  }
  
  @media (min-width: 800px) and (max-width: 1200px){
    .home-page-layout{
      @apply px-10;
    }
  }
}

