.market-chart .recharts-xAxis,.market-chart .recharts-cartesian-axis-line, .market-chart
.recharts-cartesian-axis-tick-line,.market-chart .recharts-cartesian-grid-horizontal, .market-chart
.recharts-cartesian-grid-vertical {
  display: none;
}

.market-chart
.recharts-xAxis, .recharts-cartesian-axis-line{
    margin-top: 20px;
    display: contents;
} .recharts-cartesian-axis-tick-value{
    font-size: 14px;
    font-weight: 200;
}