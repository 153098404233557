.box-style {
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.text-green {
    color: #00BF71;
}

.bg-app-green {
    background-color: #00BF71;
}

.text-red {
    color: #ED2858;
}

.mode-action{
    border: 1px solid var(--color-border-1);
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--text-normal)
}

@media (max-width: 400px) {
    .box-style {
        padding: 12px;
    }
  }

.menu2-active {
    color: #00BF71;
    border-bottom: 6px solid #00BF71;
}