.blue-circle{
    animation-delay: 0.1s;
}
.green-circle{
    animation-delay: 0.3s;
}
.red-circle{
    animation-delay: 0.5s;
}

.nav-left, .content-right {
    height: calc(100vh - 77px);
    transition: all 0.5s ease-in-out;
}

.content-top {
    height: calc(100vh - 77px - 228px);
}

.content-top, .content-botton {
    margin-inline: 200px;
}

@media screen and (max-width: 1400px) {
    .content-top, .content-botton {
        margin-inline: 100px;
    }
}

@media screen and (max-width: 1280px) {
    .content-top, .content-botton {
        margin-inline: 50px;
    }
    .nav-left {
        display: none;
        position: fixed;
        width: 300px;
    }
    .content-right {
        width: 100%;
    }
}

@media screen and (max-width: 943px) {
    .content-top {
        height: calc(100vh - 77px - 242px);
    }
}

@media screen and (max-width: 600px) {
    .btn-submit {
        padding: 10px 20px;
    }

    #template {
        max-width: 200px;
        overflow: hidden;
        word-wrap: none;
        
    }
}

@media screen and (max-width: 672px) {
    .type-ques {
        padding: 10px;
    }
    .content-top, .content-botton {
        margin-inline: 10px;
    }
    .answers {
        margin-right: 40px;
    }
    .title-logo {
        padding-block: 4px;
    }
    .title-content {
        height: fit-content;
        margin-bottom: 12px;
    }
    .main-content {
        padding: 0;
    }
    .content-top {
        height: calc(100vh - 77px - 212px);
    }
}