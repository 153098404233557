.text-color {
    color: #1D2939;
}

.menu-hover:hover {
    color: #2BCA89;
    border-bottom: #2BCA89 solid 2px;
  }

.shadown-header {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.apexcharts-toolbar {
    z-index: 0 !important;
}

.css-1o7t28p {
    padding: 6px 17px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-radius: 24px;
    cursor: pointer;
    user-select: none;
}

.css-3o0h5k {
    width: 16px;
    height: 16px;
}

.css-3o0h5k > * {
    margin-right: 12px;
}

.css-1o7t28p span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    overflow: hidden;
    white-space:  nowrap;
    animation: 1.5s linear 0s infinite alternate none;
}

@media (max-width: 1366px) and (min-width: 1000px) {
    .css-1o7t28p {
        width: 240px;
    }
}

.or-line {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    margin-bottom: 16px;
}

.or-line::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: #757575;
}

.or-line span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    padding: 0px 16px;
    z-index: 1;
    background-color: #ffffff;
}

.link {
    /* font-size: 0.75rem; */
    line-height: 1.125rem;
    font-weight: 500;
    color: #006cec;
    margin-left: 4px;
    cursor: pointer;
}
.link:hover {
    text-decoration: underline;
}

