@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import './styles/index';

html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.bg-cl-green {
  background-color: #1AAF76;
}

#root {
  height: 100%;
}

* {
    text-decoration: none;
}

.text-main-green { 
  color: #00BF71;
}

:root{
  --color-border-1: #BFC3C8;
  --bg-primary: #F7FAFF;
  --text-normal: #344054;
  --color-primary: #1AAF76;
}

.tooltip {
  @apply invisible absolute;
  text-wrap: balance;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}