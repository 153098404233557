.MarketChart{
  .recharts-cartesian-axis-line{
    display: block;
  }
  @media (max-width: 800px){
    .layout{
      @apply flex-col h-full;
      &-item-1{
        @apply w-full mb-3;
      }
      &-item-2{
        @apply w-full mb-3;
      }
    }
    .StockPanel{
      height: 336px;
    }
  } 
  @media (min-width: 1200px) and (max-width: 1500px) {
    .layout{
      &-item-1{
        @apply w-2/3 mb-3;
      }
      &-item-2{
        @apply w-1/3 mb-3;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1200px) {
    .layout{
      &-item-1{
        @apply w-1/2 mb-3;
      }
      &-item-2{
        @apply w-1/2 mb-3;
      }
    }
  }
}

.StockPanel{
  @apply overflow-auto flex-auto py-2 px-3 bg-white rounded-lg shadow-lg;
  &::-webkit-scrollbar{
    width: 0px;
  }
  .item{
    &:hover{
      background-color: #33CCFF;
    }
    &--even{
      background-color: white;
    }
    &--odd{
      background-color: #F7FAFF;
    }
    &--selected{
      background-color: #33CCFF;
    }
  }
}


  
