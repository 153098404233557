.MarketMap{
  @media (max-width: 800px){
    .Overview{
      &-item{
        @apply flex-col;
        > div{
          @apply w-full;
        }
      }
    }
  }
}
    
  