.header .menu-item-active {
    border-bottom: 2px solid #1AAF76;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto';
    color: #1AAF76;
}

.header .menu-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto';
    color: #1D2939;
}

.header .btn-login {
    padding: 19px 49px;
    background: #1AAF76;
    box-shadow: 0px 4px 8px rgba(26, 175, 118, 0.35);
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    color: #FFFFFF;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.header .title-green {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 48px;
    line-height: 150%;
    text-align: center;
    color: #1AAF76;
}

.header .title-gradient {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 48px;
    line-height: 150%;
    text-align: center;
    background: linear-gradient(93.84deg, #9100FF 69.64%, #2B7FFF 94.76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header .text-black {
    color: #1D2939;
}

.header .search-input {
    border: 2px solid #BFC3C8;
    border-radius: 12px;
    padding: 25px 32px;
    width: 950px;
    outline: none;
    color: #344054;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.header .start-search {
    background: #1AAF76;
    box-shadow: 0px 4px 8px rgba(26, 175, 118, 0.35);
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    font-family: 'Roboto';
    padding: 22px 26px;
    right: 8px;
    bottom: 8.5px;
}

@media (max-width: 1024px) {
    .header .title-green, .header .title-gradient {
        font-size: 40px;
    }
}

@media (max-width: 800px) {
    .header .title-green, .header .title-gradient {
        font-size: 35px;
    }
}

@media (max-width: 650px) {
    .header .title-green, .header .title-gradient {
        font-size: 32px;
    }
    .header .start-search {
        font-size: 16px;
        padding: 18px 22px;
        font-weight: 500;
        right: 6px;
        bottom: 6.5px;
    }
    .header .search-input {
        padding: 21px 28px;
        font-size: 16px;
        font-weight: 500;
    }
}

@media (max-width: 420px) {
    .header .title-green, .header .title-gradient {
        font-size: 32px;
    }
    .header .start-search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18px 24px;
        width: 163px;
        margin-left: 25%;
        margin-top: 24px;
    }
    .header .search-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        width: 343px;
        border-radius: 8px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .header .title-gradient {
        background: linear-gradient(82.49deg, #9100FF 30.83%, #2B7FFF 60.57%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    

}
