.Valuation{
  .overflow-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  @media (max-width: 400px){
    .width-item{
      @apply w-1/2;
    }
  }
}

