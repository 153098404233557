.main .title-green {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: #1AAF76;
    text-align: start;
}

.main .title-green-2 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 48px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #00BF71;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.main .title-gradient {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: #1AAF76;
    text-align: start;
    background: linear-gradient(84.5deg, #9100FF 1.98%, #2B7FFF 36.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main .read-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 21px 49px;
    background: #1AAF76;
    box-shadow: 0px 4px 8px rgba(26, 175, 118, 0.35);
    border-radius: 8px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.main .trans-f {
    transform: matrix(1, 0, 0, 1, 0, 0);
}

@media (max-width: 768px) {
    .main .title-green, .title-gradient {
        font-size: 26px;
    }

    .main .title-green-2 {
        font-size: 35px;
    }

    .main .read-more {
        padding: 16px 32px;
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .main .title-green, .main .title-gradient {
        font-size: 24px;
        text-align: center;
    }

    .main .title-green-2 {
        font-size: 32px;
    }
    
    .main .read-more {
        padding: 14px 28px;
        font-size: 16px;
        font-weight: 500;
    }
}

@media (max-width: 420px) {
    .main .read-more {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 24px;
        width: 100%;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
    }

    .main .title-green {
        text-align: start;
    }
}