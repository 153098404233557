.content-left {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.title-chart {
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    color: #1D2939;
}

.apexcharts-legend {
    margin-top: 12px !important;
    justify-content: space-around !important;
}

@media (max-width: 600px) {
    .title-chart {
        font-size: 24px;
    }
    .text-ssm {
        font-size: 12px;
    }
}