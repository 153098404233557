.Movement{
  .sub-container{
    @apply w-1/2 flex gap-6;
  }
  @media (max-width: 600px){
    .layout{
      @apply flex-col;
      &-item{
        @apply w-full;
      }
    }
    .sub-container{
      @apply flex-col w-full;
    }
  }
  @media (max-width: 1200px){
    .layout{
      @apply flex-col;
      &-item{
        @apply w-full;
      }
    }
  }
}
    
  