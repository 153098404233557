.Profit{
  @media (max-width: 400px){
    .layout{
      @apply flex-col;
      &-item{
        @apply w-full;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 991px){
    .layout{
      @apply flex-col;
      &-item{
        @apply w-full grid grid-cols-3 gap-4;
      }
    }
  }
  @media (min-width: 400px) and (max-width: 800px){
    .layout{
      @apply flex-col;
      &-item{
        @apply w-full grid grid-cols-2 gap-4;
      }
    }
  }
}